@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes won {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.pop-animation div {
  animation-name: pop;
  animation-duration: 0.2s;
}

.game-won div {
  animation-name: won;
  animation-duration: 0.2s;
}

.game-won > div:nth-child(2) {
  animation-delay: 0.2s;
}
.game-won > div:nth-child(3) {
  animation-delay: 0.4s;
}
.game-won > div:nth-child(4) {
  animation-delay: 0.6s;
}
.game-won > div:nth-child(5) {
  animation-delay: 0.8s;
}
